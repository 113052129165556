<h1>Hit that jukebox!</h1>
<mat-dialog-content>
  <span
    >It will play a mix of tracks played by
    <a href="https://nova.fr" target="_blank">Radio Nova</a> on same hour, same
    day.</span
  >
  <span>
    To change tracks, hit the jukebox again or skip it with Spotify.
  </span>
  <p>To start you need to plug Spotify.</p>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-raised-button mat-dialog-close color="accent">
    <span class="bold">Connect to Spotify</span>
  </button>
</mat-dialog-actions>
